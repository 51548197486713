import React from 'react'
import concertPoster from "../../zdjęcia/koncertNoworoczny.jpg"
import "./css/news.css"

function news() {
  return (
    <div className="newsComponent">
        <h2 className="invite">Zapraszamy na "Koncert Noworoczny"</h2>
        <img className="posterImg" src={concertPoster} alt="concert poster"></img>
    </div>
  )
}

export default news