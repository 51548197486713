import React from 'react'
import "./css/home.css"
import {Link} from "react-router-dom"

function collaborationButton() {
  return (
    
        <Link  to="/współpraca" className='collaborationButton'>Zagramy dla Ciebie</Link>
  )
}

export default collaborationButton