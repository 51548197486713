import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  HashRouter,
  Routes,
  Route,
  //  Link,
  // createBrowserRouter,
  // RouterProvider,
} from "react-router-dom";
import './index.css';
import Home from './components/home/home';
import About from './components/about/About'
import reportWebVitals from './reportWebVitals';
import Contact from './components/contact/Contact';
 import Gallery from './components/gallery/GalleryImages';
import Collaboration from './components/collaboration/Collaboration';
// import AboutDarkMode from './components/about/AboutDarkMode';
import Cookies from "./components/layout/cookies";
import Footer from "./components/layout/footer";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement

);



root.render(
  <React.StrictMode >
  
    <HashRouter basename={process.env.PUBLIC_URL}>
      <Routes >
              <Route path = '/' element = { <Home /> } />
              <Route path = '/o nas' element = { <About /> } />
              <Route path = '/kontakt' element = { <Contact /> } />
              <Route path = '/galeria' element = { <Gallery /> } />
              <Route path = '/współpraca' element = { <Collaboration /> } />

      </Routes>
     
   <Cookies /> 
   <Footer />
      

    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
